import "./ContactSupport.scss";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Form, Field } from "react-final-form";
import { FormApi } from "final-form";
import { styled } from "@mui/material/styles";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import InputBase from "@mui/material/InputBase";
import { SelectChangeEvent } from "@mui/material/Select";
import { Button, CircularProgress, Tooltip } from "@mui/material";
import Grid from "@mui/material/Grid";
import { ContactFormTopicEnum } from "../../redux/models/contactForm";
import {
  selectContactForm,
  selectContactLoading,
  selectContactSubmitLoading,
} from "../../redux/selectors/contact.selector";
import { reportActions } from "../../redux/actions/report.actions";
import {
  isReportsLoading,
  selectAllReports,
} from "../../redux/selectors/report.selector";
import { contactActions } from "../../redux/actions/contact.actions";
import { notificationActions } from "../../redux/actions/notification.actions";
import { INotificationType } from "../../redux/models/notificationType";

interface GenericSelectItem {
  value: number | string;
  name: string;
  icon?: JSX.Element;
  iconTooltip?: string;
  subtitle?: string;
  form?: FormApi<any, Partial<any>>;
}

interface InputProps {
  input: any;
  label: string;
  placeholder: string;
  disabled: boolean;
  multiLine: boolean;
  meta: any;
}

interface SelectProps {
  input: any;
  label: string;
  children: GenericSelectItem[];
  disabled: boolean;
  control: any;
  meta: any;
}

const SelectInput = styled(InputBase)(({ theme }) => ({
  "& .MuiInputBase-input": {
    backgroundColor: "rgba(85, 136, 163, 0.2)",
    borderBottom: "2px solid #5588a3",
    fontSize: "1em",
    padding: "10px 16px 10px 12px",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    "&:focus": {
      border: "2px solid #5588a3",
    },
    "&.Mui-disabled": {
      backgroundColor: "#e8e8e8",
    },
  },
}));

function ContactFormComponent() {
  const dispatch = useDispatch();

  const contactForm = useSelector(selectContactForm);
  const contactFormLoading = useSelector(selectContactLoading);
  const contactSubmitLoading = useSelector(selectContactSubmitLoading);
  const reportsLoading = useSelector(isReportsLoading);
  const [selectedTopic, setSelectedTopic] = useState<ContactFormTopicEnum>();
  const reports = useSelector(selectAllReports);

  const topics: GenericSelectItem[] = [
    {
      value: ContactFormTopicEnum.Portal,
      name: "Website/Portal",
    } as GenericSelectItem,
    {
      value: ContactFormTopicEnum.Report,
      name: "Report",
    } as GenericSelectItem,
    {
      value: ContactFormTopicEnum.Subscriptions,
      name: "Subscriptions",
    } as GenericSelectItem,
    {
      value: ContactFormTopicEnum.FileStorage,
      name: "File Storage",
    } as GenericSelectItem,
    {
      value: ContactFormTopicEnum.Dashboard,
      name: "Dashboard",
    } as GenericSelectItem,
    {
      value: ContactFormTopicEnum.Other,
      name: "Other",
    } as GenericSelectItem,
  ];

  const reportItems: GenericSelectItem[] = [
    ...reports.map((r) => {
      return {
        value: r.reportId,
        name: r.name,
      } as GenericSelectItem;
    }),
  ];

  // const requiredString = (value: string) => {
  //   return value && value.length > 0 ? undefined : "is required";
  // };
  const requiredStringLessThan1000 = (value: string) => {
    if (!value) return "is required";
    return value.length > 0 && value.length < 1000
      ? undefined
      : `cannot be longer than 1000 characters. Currently ${value?.length}`;
  };
  const requiredNumber = (value: number) => {
    return value > 0 ? undefined : "is required";
  };

  const requiredEmail = (value: string) => {
    if (/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(value))
      return undefined;
    return "is an invalid email";
  };
  const renderTextField = ({
    input,
    label,
    placeholder,
    disabled,
    meta: { touched, error },
  }: InputProps) => {
    return (
      <div>
        <input
          label={label}
          placeholder={placeholder}
          {...input}
          disabled={disabled}
        />
        {touched && error && (
          <span className="error small-text">
            {label} {error}
          </span>
        )}
      </div>
    );
  };

  const renderTextAreaField = ({
    input,
    label,
    placeholder,
    disabled,
    meta: { touched, error },
  }: InputProps) => {
    return (
      <div>
        <textarea placeholder={placeholder} {...input} disabled={disabled} />
        {touched && error && (
          <span className="error small-text" style={{ display: "contents" }}>
            {label} {error}
          </span>
        )}
      </div>
    );
  };

  const renderSelectTopicField = ({
    input,
    label,
    children,
    disabled,
    control,
    meta: { touched, error },
  }: SelectProps) => (
    <div>
      <Select
        className="select"
        value={input?.value === 0 ? "" : input.value}
        onChange={(e: SelectChangeEvent<ContactFormTopicEnum>) => {
          input.onChange(e);
          handleOnChange(e);
          if (e.target.value === "") return;
          let val = e.target.value as ContactFormTopicEnum;
          if (val === ContactFormTopicEnum.Report) {
            dispatch(reportActions.getReports(false));
          }
          setSelectedTopic(val);
        }}
        input={control}
        disabled={disabled}
        displayEmpty
        renderValue={(selected) => {
          if (selected) {
            let chosenTopic = topics.find((t) => t.value === selected);
            return chosenTopic?.name;
          }
          return <em>Choose...</em>;
        }}
      >
        {children.map((val) => getMenuItem(val))}
      </Select>
      {touched && error && (
        <span className="error small-text">
          {label} {error}
        </span>
      )}
    </div>
  );

  const renderSelectReportField = ({
    input,
    label,
    children,
    disabled,
    control,
    meta: { touched, error },
  }: SelectProps) => (
    <div>
      <Select
        className="select"
        value={input?.value === 0 ? "" : input.value}
        onChange={(e: SelectChangeEvent<number | string>) => {
          input.onChange(e);
          handleOnChange(e);
        }}
        input={control}
        disabled={disabled}
        displayEmpty
        renderValue={(selected) => {
          if (reportsLoading) {
            return "Loading...";
          }
          if (selected) {
            var chosenReport = reportItems.find((r) => r.value === selected);
            return chosenReport?.name;
          }
          return <em>Choose...</em>;
        }}
      >
        {children.map((val) => getMenuItem(val))}
      </Select>
      {touched && error && (
        <span className="error small-text">
          {label} {error}
        </span>
      )}
    </div>
  );

  const handleOnChange = (e: any) => {};

  const handleResetForm = (form: FormApi<any, Partial<any>>) => {
    form.reset();
    setSelectedTopic(undefined);
  };

  const getMenuItem = (val: GenericSelectItem) => {
    return (
      <MenuItem
        key={val.value}
        value={val.value}
        className="medium-text blue-text"
      >
        <div className="cf-menu-item-inner">
          <div>{val.name}</div>
          {val.icon && (
            <Tooltip title={val.iconTooltip ?? ""} placement="right">
              {val.icon}
            </Tooltip>
          )}
          {val.subtitle && <span className="subtitle">{val.subtitle}</span>}
        </div>
      </MenuItem>
    );
  };

  return (
    <div id="contact-form-outer">
      <Form
        initialValues={{
          contactForm: contactForm,
        }}
        // mutators={{
        //   resetCategory: (args, state, utils) => {
        //     utils.changeValue(state, "selectedReport.categoryId", () => 0);
        //   },
        // }}
        // debug={formDebug}
        onSubmit={(values: any) => {
          console.log("submitting");
          dispatch(
            contactActions.submitContactForm(values.contactForm, () =>
              dispatch(
                notificationActions.setNotification(
                  INotificationType.Success,
                  "Your request was submitted successfully.",
                  false
                )
              )
            )
          );
        }}
        render={({ handleSubmit, form, submitting }) => (
          <form
            id="contact-form"
            onSubmit={handleSubmit}
            onChange={handleOnChange}
          >
            <div id="contact-form-main">
              <div className="title large-text">
                <span>Contact Support</span>
                {contactFormLoading && (
                  <span style={{ marginLeft: 20 }}>
                    <CircularProgress size={20} />
                  </span>
                )}
              </div>
              <div className="form-container">
                <Grid container spacing={4} columns={16}>
                  {/* <Grid item md={8} sm={16}>
                    <div className="label-cell">Sender Email *</div>
                    <div className="control-cell">
                      <Field
                        name="contactForm.senderEmailAddress"
                        component={renderTextField}
                        label="Sender Email"
                        placeholder="Sender Email"
                        disabled={true}
                        validate={requiredString}
                      />
                    </div>
                  </Grid>
                  <Grid item md={8} sm={16}>
                    <div className="label-cell">Sender Name *</div>
                    <div className="control-cell">
                      <Field
                        name="contactForm.senderName"
                        component={renderTextField}
                        label="Sender Name"
                        placeholder="Sender Name"
                        disabled={true}
                        validate={requiredString}
                      />
                    </div>
                  </Grid> */}
                  <Grid item md={8} sm={16}>
                    <div className="label-cell">Email Address *</div>
                    <div className="control-cell">
                      <Field
                        name="contactForm.replyToEmailAddress"
                        component={renderTextField}
                        label="Email Address"
                        placeholder="Reply To Email"
                        validate={requiredEmail}
                      />
                    </div>
                  </Grid>
                  <Grid item md={8} />
                  <Grid item md={8} sm={16}>
                    <div className="label-cell">Topic *</div>
                    <div className="control-cell">
                      <Field
                        name="contactForm.topic"
                        component={renderSelectTopicField}
                        label="Topic"
                        children={topics}
                        control={<SelectInput />}
                        validate={requiredNumber}
                      />
                    </div>
                  </Grid>
                  {selectedTopic === ContactFormTopicEnum.Report && (
                    <Grid item md={8} sm={16}>
                      <div className="label-cell">Report *</div>
                      <div className="control-cell">
                        <Field
                          name="contactForm.selectedReportId"
                          component={renderSelectReportField}
                          label="Report"
                          children={reportItems}
                          control={<SelectInput />}
                          disabled={reportsLoading}
                          validate={requiredNumber}
                        />
                        {reportsLoading && (
                          <div className="loading">
                            <CircularProgress size={15} />
                          </div>
                        )}
                      </div>
                    </Grid>
                  )}
                  <Grid item xs={16}>
                    <div className="label-cell">
                      <span>Message *</span>
                      <span style={{ marginLeft: 10 }} className="msmall-text">
                        (max 1000 characters)
                      </span>
                    </div>
                    <div className="control-cell">
                      <Field
                        name="contactForm.message"
                        component={renderTextAreaField}
                        label="Message"
                        placeholder="Enter your message here"
                        multiLine={true}
                        validate={requiredStringLessThan1000}
                      />
                    </div>
                  </Grid>
                </Grid>
              </div>
              <div className="btn-row">
                <button
                  type="submit"
                  className="save-button"
                  disabled={submitting || contactSubmitLoading}
                >
                  {contactSubmitLoading ? (
                    <div>
                      <CircularProgress size={25} />
                    </div>
                  ) : (
                    <div onClick={handleSubmit}>Submit</div>
                  )}
                </button>

                <Button
                  onClick={() => handleResetForm(form)}
                  className="cancel-button"
                >
                  Clear
                </Button>
              </div>
            </div>
          </form>
        )}
      />
    </div>
  );
}
export default ContactFormComponent;
