import "./ContactSupport.scss";
import { useEffect } from "react";
import { useDispatch } from "react-redux";

import ContactFormComponent from "./ContactFormComponent";
import { contactActions } from "../../redux/actions/contact.actions";

function ContactPage() {
  const dispatch = useDispatch();

  // Called on initial render
  useEffect(() => {
    dispatch(contactActions.getContactForm());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div id="contact-page">
      <ContactFormComponent />
    </div>
  );
}
export default ContactPage;
